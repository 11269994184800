// Fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import '~@mdi/font/css/materialdesignicons.css';

/* - FontAwesome 5 - */
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";

// Variables
@import 'variables';

// Bootstrap
//@import '~bootstrap/scss/bootstrap';

@import "~animate.css/animate.css";

:root {
    /* - Colors */
    --apsto-black-light: rgba(0, 0, 0, .60);
    --apsto-black: rgba(0, 0, 0, .87);
    --apsto-border: rgba(0, 0, 0, .15);
    --apsto-cyan: #6cb2eb;
    --apsto-purple-dark: #31164A;
    --apsto-purple-light: #AF78D3;
    --apsto-purple-very-light: #F8F5F9;
    --apsto-purple: #4D226A;;
    --apsto-teal-dark: #4b979b;
    --apsto-teal-light: #CBE5E6;
    --apsto-teal-very-light: #EEF6F7;
    --apsto-teal: #55A9AD;

    /* - Fonts */
    --font-TITLE: 'Roboto Slab', sans-serif !important;
    --font-BODY: 'Mulish', sans-serif !important;
}

.w-auto {
    width: auto !important;
}

.w-30 {
    width: 30% !important;
}

.w-70 {
    width: 70% !important;
}

.w-100 {
    width: 100% !important;
}

.cancel-button {
    background-color: var(--apsto-teal) !important;
    border: 1px solid var(--apsto-teal-dark);
    color: white !important;
    font-weight: 900 !important;
    min-width: 120px !important;
}

.save-by-cancel-button {
    border: 1px solid var(--apsto-purple) !important;
    min-width: 120px !important;
}

.dark-purple-background {
    background-color: var(--apsto-purple-dark) !important;
}

.donation-header-strip {
    align-items: center;
    background-color: var(--apsto-teal);
    display: flex;
    height: 50px;
    padding: 0 20px;
    width: 100%;
}

.error-message {
    background-color: var(--apsto-purple-very-light);
    padding: 20px;
}

.failure-box {
    background-color: red;
    color: white;
    font-weight: bold;
    padding: 20px;
}

.grey-background {
    background-color: var(--apsto-purple-very-light) !important;
}

.heading-mulish {
    font-family: var(--font-BODY) !important;
    font-size: 1.125rem;
    font-weight: 500;
}

.save-by-cancel-button {
    border: 1px solid var(--apsto-purple) !important;
}

.purple-background {
    background-color: var(--apsto-purple) !important;
}

.slide-fade-enter,
.slide-fade-leave-to {
    opacity: 0;
    transform: translateY(-20px);
}

.slide-fade-enter-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.box-with-background {
    background-color: var(--apsto-teal);
    color: white;
    font-weight: bold;
    padding: 20px;
}

.box-with-background.purple-background {
    background-color: var(--apsto-purple);
}

.text-apsto-black {
    color: var(--apsto-black) !important;
}

.text-apsto-purple {
    color: var(--apsto-purple);
}

.text-apsto-red {
    color: red;
}

.text-apsto-teal {
    color: var(--apsto-teal);
    font-weight: 700;
}

.text-apsto-white {
    color: white;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.v-application {
    font-family: var(--font-BODY) !important;

    .font-roboto-slab {
        font-family: Roboto Slab, serif !important;
    }

    .radio-label,
    .text-h7 {
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 8px;
    }

    .text-h5-mulish {
        font-family: "Mulish", sans-serif !important;
        font-size: 1.5rem !important;
        font-weight: 400;
        letter-spacing: normal !important;
        line-height: 1.6rem;
    }

    .text-h6-mulish {
        font-family: "Mulish", sans-serif !important;
        font-size: 1.25rem !important;
        font-weight: 500;
        letter-spacing: 0.0125em !important;
        line-height: 1.6rem;
    }

    .text-h7-mulish {
        font-family: "Mulish", sans-serif !important;
        font-size: 1.10rem !important;
        font-weight: 500;
        letter-spacing: 0.0125em !important;
        line-height: 1.4rem !important;
    }

    .font-mulish {
        font-family: "Mulish", sans-serif !important;
    }

    .v-btn.guest-mimic-logged-in-btn,
    .v-dialog.v-dialog--active .v-btn {
        height: auto !important;
        padding: 12px 20px !important;
    }

    .v-main#dashboard .v-btn {
        padding: 12px 20px !important;
    }

    .v-card.dialog {

        border-radius: 0 !important;

        .v-card__title {
            background-color: var(--apsto-teal);
            color: white;
        }
    }

    .v-data-table .v-toolbar__content {
        color: white;
        font-weight: 700;
    }

    .v-data-table__mobile-row:not(:last-child) {
        display: table;
        padding: 10px 16px !important;
    }

    .v-data-table__mobile-row__cell {
        font-weight: bold;
        text-align: left !important;
    }

    .v-data-table__mobile-row__header {
        color: var(--apsto-black-light);
    }

    .v-data-table__mobile-table-row {
        border-radius: 5px !important;
        margin: 5px 2px;
    }

    .v-data-table__mobile-table-row:nth-child(even) {
        border-left: 6px solid var(--apsto-teal);
    }

    .v-data-table__mobile-table-row:nth-child(odd) {
        border-left: 6px solid var(--apsto-purple);
    }

    .v-input__slot.white {
        background-color: #EFE9F1 !important;
    }

    .v-messages__message {
        background-color: var(--apsto-teal-very-light);
        color: black;
        font-size: 16px;
        line-height: 1.5;
        margin: 8px -8px;
        padding: 8px 8px;
    }

    .v-snack__content {
        font-weight: 700;
    }

    .v-snack__wrapper.theme--dark {
        background-color: var(--apsto-purple);
        text-transform: uppercase;
    }

    .v-snack__btn .v-btn__content {
        color: var(--apsto-teal);
        font-weight: 700;
    }

    [class*="text-"] {
        font-family: $text-font-family, sans-serif !important;
    }

    li {
        font-size: 1.125rem;
        margin-bottom: 10px;
    }

    p {
        font-size: 1.125rem;
    }
}

.v-card__text,
.v-card__title {
    word-break: normal !important;
}

.v-expansion-panel {
    margin-bottom: 20px;
}

a {
    text-decoration: none;
}

a:not([role="menuitem"]) {
    font-weight: 600;
}

button.primary {
    border: 1px solid var(--apsto-purple) !important;
}

h1,
h2,
h3 {
    font-family: var(--font-TITLE) !important;
}

h4,
h5,
h6 {
    font-family: var(--font-BODY) !important;
}

ul[data-checked] {
    list-style: none;
    padding-left: 0px !important;
}

ul[data-checked] li {
    //padding-left: 1.5em;
    position: relative;
}

ul[data-checked] li:before {
    content: '';
    /* placeholder for the SVG */
    //position: absolute;
    //left: 0;
    padding-right: 28px;
    top: 4px;
    /* place the SVG at the start of the padding */
    width: 1em;
    height: 1em;
    background: url("data:image/svg+xml;utf8,<?xml version='1.0' encoding='utf-8'?><svg width='18' height='18' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg' ><path fill='%2355a9ad' d='M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z'/></svg>") no-repeat;
}

.ql-editor p {
    margin-bottom: 16px !important;
}

.portal-check {
    .message-continue {
        color: var(--apsto-purple);
        font-family: 'Mulish', sans-serif !important;
        font-size: 1.625rem !important;
        font-weight: 900;
        letter-spacing: 0.0073529412em !important;
        line-height: 2.5rem;
    }

    .message-instructions {
        color: var(--apsto-black-light);
        font-family: 'Mulish', sans-serif !important;
        font-size: 1.25rem !important;
        font-weight: 400;
        letter-spacing: 0.009375em !important;
        line-height: 1.6rem;
    }

    .message-salutation {
        color: var(--apsto-purple);
        font-family: 'Mulish', sans-serif !important;
        font-size: 2.125rem !important;
        font-weight: 900;
        letter-spacing: 0.0073529412em !important;
        line-height: 2.5rem;
    }

    .message-welcome {
        color: var(--apsto-teal);
        font-family: 'Roboto Slab', serif;
        font-size: 1.625rem;
    }


}
